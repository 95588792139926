/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 09:48:11
 * @LastEditTime: 2022-02-25 11:22:05
 */
import asyncRouters from '@/router/asyncRouter'
import basicRouters from '@/router/basicRouter'

function hasPermission(permission, route) {
	if (route.meta && route.meta.permission) {
		let flag = false
		for (let i = 0, len = permission.length; i < len; i++) {
			flag = route.meta.permission.includes(permission[i])
			if (flag) {
				return true
			}
		}
		return false
	}
	return true
}

function filterAsyncRouter(routerMap, roles) {
	const accessedRouters = routerMap.filter(route => {
		if (hasPermission(roles.permissionList, route)) {
			if (route.children && route.children.length) {
				route.children = filterAsyncRouter(route.children, roles)
			}
			return true
		}
		return false
	})
	return accessedRouters
}
const permission = {
	state: {
		routers: basicRouters,
		addRouters: []
	},
	mutations: {
		SET_ROUTERS: (state, routers) => {
			state.addRouters = routers
			state.routers = basicRouters.concat(routers)
		}
	},
	actions: {
		GenerateRoutes({
			commit
		}, data) {
			return new Promise(resolve => {
				const {
					roles
				} = data
				const accessedRouters = filterAsyncRouter(asyncRouters, roles)
				// console.log("accessedRouters", accessedRouters, roles)
				if (accessedRouters && accessedRouters.length > 0) {
					accessedRouters.forEach(itemOne => {
						if (itemOne.children && itemOne.children.length > 0) {
							itemOne.children.forEach(itemTwo => {
								if (itemTwo.meta && itemTwo.meta.title) {
									if (roles.permissions && roles.permissions.length > 0) {
										roles.permissions.forEach(itemThree => {
											if(itemTwo.meta.title == itemThree.permissionName) {
												itemTwo.meta['secondaryMenu'] = itemThree.secondaryMenu
											}
										})
									}
								}
							})
						}
					})
					console.log(accessedRouters,"SET_ROUTERS======....")
				}
				commit('SET_ROUTERS', accessedRouters)
				resolve()
			})
		}
	}
}

export default permission
