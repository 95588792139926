/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-04-19 16:41:44
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import animated from 'animate.css'
// 引入自定义指令
import globalPul from "./utils/directive.js"
globalPul.install(Vue)
// 引入Echarts
import * as Echarts from 'echarts'
// 样式统一文件
import 'element-ui/lib/theme-chalk/display.css';
import 'normalize.css/normalize.css'
import '@/styles/index.scss'
// import './utils/rem'
// 按需引入element组件
// import './libs'
import ElementUI from 'element-ui'
// 全部引入element组件
Vue.use(ElementUI)
Vue.use(animated)
// 引入富文本编辑框
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
import loading from './components/Loading/loading'
Vue.use(loading)
// 权限处理
import './permission'
// 全局图标
// import './icons'
// 引入iconfont
import './assets/icon/iconfont.css'
import './components/icon-svg/index'
// 引入全局自定义确认弹窗
import messegeBox from './components/messegeBox/index.vue'
Vue.component('messege-box', messegeBox)
import previewImage from './components/previewImage/index.vue'
Vue.component('preview-image', previewImage)
Vue.config.productionTip = false
Vue.prototype.$Echarts = Echarts
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
