<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-02-25 10:54:13
 * @LastEditTime: 2022-02-25 11:17:50
-->
<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '@/styles/index.scss';
  .app{
    width: 100%;
    height: 100%;
  }
</style>
