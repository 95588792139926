/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 11:38:44
 * @LastEditTime: 2021-06-10 15:46:14
 */
const getters = {
    roles: state => state.user.roles,
    addRouters: state => state.permission.addRouters,
}
export default getters