/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-27 18:15:12
 * @LastEditTime: 2022-03-24 13:43:43
 */
import axios from 'axios'
import storage from "store";
import store from '@/store'
import Vue from 'vue'
import {
	Message,
	Notification
} from 'element-ui'
import {
	VueAxios
} from './axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const request = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 10000
})

// 异常拦截处理器
const errorHandler = (error) => {
	if (error.response) {
		const data = error.response.data
		const token = storage.get('ACCESS_TOKEN')
		Message({
		  message: data.msg,
		  type: 'error'
		})
		// Notification({
		// 	type: 'error',
		// 	message: data.msg,
		// 	title: '错误'
		// })
		if (data.code == '4001') {
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		}
	}
	// setTimeout(() => {
	// 	Vue.$loading.hide()
	// }, 1000)
	return Promise.reject(error)
}

// 请求拦截器
request.interceptors.request.use(config => {
	// Vue.$loading.show()
	const token = storage.get('ACCESS_TOKEN')
	if (token) {
		// console.log("登录token", token)
		config.headers['x-yourise-auth-token'] = token
	}
	return config
}, errorHandler)

// 响应拦截器
request.interceptors.response.use(res => {
	// setTimeout(() => {
	// 	Vue.$loading.hide()
	// }, 1000)
	const code = res.data.code
	if (code == '0') {
		return res.data
	} else {
		const token = storage.get('ACCESS_TOKEN')
		Message({
		  message: res.data.msg,
		  type: 'error'
		})
		// Notification({
		// 	type: 'error',
		// 	message: res.data.msg,
		// 	title: '错误'
		// })
		if (code == '4001') {
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		}
	}
}, errorHandler)
const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request)
	}
}

export default request

export {
	installer as VueAxios,
	request as axios
}
