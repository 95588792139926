<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeMessege" :width="messegeSize" :visible.sync="messegeVisible" :close-on-click-modal="false"
		:show-close="false">
		<div class="messege_box">
			<img @click="closeMessege" src="@/assets/images/common/close.png" class="messege_close">
			<div class="messege_title">
				<img v-if="messegeType == 'warn'" src="@/assets/images/common/warn.png" class="messege_icon">
				<span class="messege_tip">{{messegeTip}}</span>
			</div>
			<div class="messege_content">
				{{messegeContent}}
			</div>
			<div class="submit_btn">
				<span @click="closeMessege" class="submit_btn_cancel">取消</span>
				<span v-preventReClick @click="confirmMessege" class="submit_btn_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			isOpen: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			messegeSize: {
				type: String,
				default: function() {
					return "420px"
				}
			},
			messegeTip: {
				type: String,
				default: function() {
					return ""
				}
			},
			messegeContent: {
				type: String,
				default: function() {
					return ""
				}
			},
			messegeType: {
				type: String,
				default: function() {
					return "warn"
				}
			},
		},
		watch: {
			isOpen: {
				handler(val) {
					console.log("isOpen", val)
					this.messegeVisible = val
				},
				deep: true
			}
		},
		data() {
			return {
				messegeVisible: false
			}
		},
		methods: {
			closeMessege() {
				this.$emit('closeMessege')
			},
			confirmMessege() {
				this.$emit('confirmMessege')
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	/deep/.el-dialog {
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0;
	}

	.submit_btn {
		display: flex;
		align-items: center;
		margin-top: 49px;

		.submit_btn_cancel {
			margin-left: auto;
			width: 76px;
			line-height: 34px;
			background: #FFFFFF;
			border: 1px solid $theme_color;
			border-radius: 4px;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: $theme_color;
			text-align: center;
			cursor: pointer;
			margin-right: 16px;
		}

		.submit_btn_confirm {
			width: 76px;
			line-height: 34px;
			background: $theme_color;
			border-radius: 4px;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFF;
			text-align: center;
			cursor: pointer;
		}
	}

	.messege_box {
		height: 200px;
		padding: 28px 24px;
		border-radius: 4px;
		background: #FFFFFF;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		position: relative;

		.messege_close {
			width: 14px;
			height: 14px;
			position: absolute;
			right: 24px;
			top: 16px;
			z-index: 999;
			cursor: pointer;
		}

		.messege_title {
			display: flex;
			align-items: center;

			.messege_icon {
				width: 18px;
				height: 18px;
				margin-right: 8px;
			}

			.messege_tip {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 18px;
				color: #333333;
			}
		}

		.messege_content {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 24px;
			color: #666666;
			margin-top: 20px;
		}
	}
</style>
