/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-09 17:59:18
 * @LastEditTime: 2021-06-09 18:08:49
 */
import storage from 'store'
import {
	getBadgeNum,
	clearBadgeNum,
} from "@/api/login"
import {
	readStudentUpdate
} from "@/api/dashboards"
const breadcrumb = {
	state: {
		breadcrumbArr: [],
		badgeNum: 0
	},
	mutations: {
		SET_BREAD: (state, intArr) => {
			state.breadcrumbArr = intArr
		},
		SET_BADGE: (state, intNum) => {
			state.badgeNum = intNum
			storage.set('ACCESS_BADGE', intNum, 7 * 24 * 60 * 60 * 1000)
		},
	},
	actions: {
		changeBread({
			commit
		}, intArr) {
			commit('SET_BREAD', intArr)
		},
		getBadgeNum({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getBadgeNum().then((res) => {
					// console.log("通知未读数", res.data)
					commit('SET_BADGE', res.data)
					resolve(res.data)
				}).catch(error => {
					commit('SET_BADGE', 0)
					reject()
				})
			})
		},
		badgeNumClear({
			commit
		}, params) {
			return new Promise((resolve, reject) => {
				clearBadgeNum(params).then((res) => {
					if(res.code == 0) {
						resolve(res)
					} else {
						reject()
					}
				})
			})
			
		},
		studentUpdateClear({
			commit
		}, params) {
			return new Promise((resolve, reject) => {
				readStudentUpdate(params).then((res) => {
					if(res.code == 0) {
						resolve(res)
					} else {
						reject()
					}
				})
			})
			
		},
	}
}
export default breadcrumb
