import axios from '@/utils/request'
const Api = {
	taskCalendar: "/api/backlogTask/getListForCalendar",
    taskList:"/api/backlogTask/getListForToday",
	taskDetail: "/api/backlogTask/getById",
    taskDel:"/api/backlogTask/delById",
    taskQuery:"/api/backlogTask/getById",
    taskAdd:"/api/backlogTask/save",
	taskEdit: "/api/backlogTask/updateById",
  taskHistory:"/api/backlogTaskAppoint/getListBybacklogTaskId",
  taskStatistical:"/api/backlogTask/getStatistics",
  taskOtherTime:"/api/backlogTask/getListByCustom",
  taskHomeList:"/api/viewTask/getListForHome",
  syList:"/api/backyard/schoolPaperwork/sy/list",
  messageRead:"/api/backyard/schoolSelection/read",
	studentUpdateRead: "/api/updateNotify/markRead"
}
// 代办日历
export function calendarTask (params) {
    return axios({
      method: 'post',
      url: Api.taskCalendar,
      data: params
    })
}
// 代办详情
export function detailTask (params) {
    return axios({
      method: 'post',
      url: Api.taskDetail,
      data: params
    })
}

//任务代办列表
export function getTaskList (params) {
    return axios({
      method: 'post',
      url: Api.taskList,
      data: params
    })
}
//任务代办删除
export function delTask (params) {
    return axios({
      method: 'post',
      url: Api.taskDel,
      data: params
    })
}
//查询待办任务
export function queryTask (params) {
    return axios({
      method: 'post',
      url: Api.taskQuery,
      data: params
    })
}
//新增待办任务
export function addTask (params) {
    return axios({
      method: 'post',
      url: Api.taskAdd,
      data: params
    })
}

// 编辑待办任务
export function editTask (params) {
    return axios({
      method: 'post',
      url: Api.taskEdit,
      data: params
    })
}
// 获取代办历史
export function historyTask (params) {
  return axios({
    method: 'post',
    url: Api.taskHistory,
    data: params
  })
}
// 待办任务统计
export function statisticalTask (params) {
  return axios({
    method: 'post',
    url: Api.taskStatistical,
    params: params
  })
}
// 其他时间已完成任务
export function otherTimeTask (params) {
  return axios({
    method: 'post',
    url: Api.taskOtherTime,
    data: params
  })
}
//首页任务
export function listTaskHome (params) {
  return axios({
    method: 'post',
    url: Api.taskHomeList,
    data: params
  })
}
//查看首页文书列表
export function listSy (params) {
  return axios({
    method: 'get',
    url: Api.syList,
    params: params
  })
}
//首页消息点击已读
export function readMessage (params) {
  return axios({
    method: 'get',
    url: Api.messageRead,
    params: params
  })
}
// 学生列表更新已读
export function readStudentUpdate (params) {
  return axios({
    method: 'post',
    url: Api.studentUpdateRead,
    data: params
  })
}


