//指令全局监听事件并阻止短时间内连续触发
const globalPul = {
	install: function(Vue, pluOp = {}) {
		// 全局点击事件监听阻止
		Vue.directive('preventReClick', {
			inserted(el, binding) {
				el.addEventListener('click', (e) => {
				if (!el.$disabled) {
						el.$disabled = true;
						//#ifdef APP-PLUS
						el.$click=[...el.events.click];
						el.events.click = [];
						//#endif
						setTimeout(() => {
							//#ifdef APP-PLUS
							el.events.click = el.$click;
							//#endif
							el.$disabled = false;
					
						}, binding.value || 1000)
					
					}
				})
				el.addEventListener('tap', (e) => {
				if (!el.$disabled) {
						el.$disabled = true;
						el.tap=[...el.events.tap];
						el.events.tap = [];
						setTimeout(() => {
							el.events.tap = el.tap;
							el.$disabled = false;
					
						}, binding.value || 1000)
					
					}
				})
			}
		})
	}
};

export default globalPul