const routeData = {
    state: {
        routeData: {}
    },
    mutations: {
        SET_DATA: (state, intData) => {
            state.routeData = intData
        }
    },
    actions: {
        sendRouteData({ commit }, intData) {
            commit('SET_DATA', intData)
        }
    }
}
export default routeData