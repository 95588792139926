/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-02-25 14:46:52
 */
// const RouteView = {
//   name: 'RouteView',
//   render: (h) => h('router-view')
// }
const asyncRouters = [{
		path: '/',
		name: '',
		component: () => import('@/layout'),
		meta: {
			title: '予睿教育'
		},
		redirect: '/dashboard',
		children: [{
				path: '/dashboard',
				name: 'Dashboard',
				component: () => import('@/views/dashboard'),
				meta: {
					title: '首页',
					icon: 'el-icon-rank',
					permission: ['Index']
				}
			},
			{
				path: '/studentInformation',
				name: 'StudentInformation',
				component: () => import('@/views/studentInformation'),
				meta: {
					title: '学生信息',
					icon: 'el-icon-rank',
					permission: ['Student']
				}
			},
			{
				path: '/taskProgress',
				name: 'TaskProgress',
				component: () => import('@/views/taskProgress'),
				meta: {
					title: '任务进度',
					icon: 'el-icon-rank',
					permission: ['Task']
				}
			},
			{
				path: '/applicationProgress',
				name: 'ApplicationProgress',
				component: () => import('@/views/applicationProgress'),
				meta: {
					title: '申请进度',
					icon: 'el-icon-rank',
					permission: ['Application']
				}
			},
			{
				path: '/knowledgeBase',
				name: 'KnowledgeBase',
				component: () => import('@/views/knowledgeBase'),
				meta: {
					title: '知识库',
					icon: 'el-icon-rank',
					permission: ['KnowledgeBase']
				}
			},
			{
				path: '/knowledgeBase/activity',
				name: 'KnowledgeBaseActivity',
				component: () => import('@/views/knowledgeBase/activity'),
				meta: {
					title: '活动',
					icon: 'el-icon-rank',
					permission: ['KnowledgeBase'],
					parentRoute: 'KnowledgeBase',
					hidden: true
				},
			},
			{
				path: '/knowledgeBase/summer',
				name: 'KnowledgeBaseSummer',
				component: () => import('@/views/knowledgeBase/summer'),
				meta: {
					title: '夏校',
					icon: 'el-icon-rank',
					permission: ['KnowledgeBase'],
					parentRoute: 'KnowledgeBase',
					hidden: true
				},
			},
			{
				path: '/industryInformation',
				name: 'IndustryInformation',
				component: () => import('@/views/industryInformation'),
				meta: {
					title: '行业资讯',
					icon: 'el-icon-rank',
					permission: ['News']
				}
			},
			{
				path: '/setUp',
				name: 'SetUp',
				component: () => import('@/views/setUp'),
				meta: {
					title: '设置',
					icon: 'el-icon-rank',
					permission: ['System']
				}
			},
			{
				path: '/dashboard/taskStatistics',
				name: 'TaskStatistics',
				component: () => import('@/views/dashboard/taskStatistics'),
				meta: {
					title: '任务统计',
					icon: 'el-icon-rank',
					permission: ['Index'],
					parentRoute: 'Dashboard',
					hidden: true
				},
			}
		]
	},
	{
		path: '*',
		redirect: '/404'
	}
]
export default asyncRouters
