/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 10:20:32
 * @LastEditTime: 2022-02-25 11:32:00
 */
import storage from 'store'
import {
	authLogin,
	getUserInfo,
	getPermissionInfo
} from "@/api/login"
const user = {
	state: {
		token: '',
		name: '',
		roles: [],
		avatar: "",
		teacherId:""
	},
	mutations: {
		SET_TOKEN: (state, intToken) => {
			state.token = intToken
			storage.set('ACCESS_TOKEN', intToken, 7 * 24 * 60 * 60 * 1000)
		},
		SET_ROLES: (state, intRoles) => {
			state.roles = intRoles
			storage.set('ACCESS_ROLES', intRoles, 7 * 24 * 60 * 60 * 1000)
		},
		SET_NAME: (state, intName) => {
			state.name = intName
			storage.set('ACCESS-NAME', intName)
		},
		SET_AVATAR: (state, intAvatar) => {
			state.avatar = intAvatar
			storage.set('ACCESS-AVATAR', intAvatar)
		},
		SET_TEACHERID: (state, intTeacherid) => {
			state.teacherId = intTeacherid
			storage.set('ACCESS-TEACHERID', intTeacherid)
		},
	},
	actions: {
		Login({
			commit
		}, intParams) {
			return new Promise((resolve, reject) => {
				authLogin(intParams).then((res) => {
					console.log("登录信息", res)
					commit('SET_TOKEN', res.data)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		GetUserInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getUserInfo().then((res) => {
					console.log("用户信息", res)
					commit('SET_NAME', res.data.realname)
					commit("SET_AVATAR", res.data.avatarUrl)
					commit("SET_TEACHERID", res.data.id)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		GetPermissionInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getPermissionInfo().then((res) => {
					console.log("权限信息>>>>", res)
					let permissionArr = []
					if (res.data.permissionVOList && res.data.permissionVOList.length > 0) {
						permissionArr = res.data.permissionVOList.map(item => {
							return {
								permissionId: item.resourceCode,
								permissionName: item.displayName,
								secondaryMenu: item.children ? item.children : null
							}
						})
					} else {
						permissionArr = [{
							permissionId: '',
							permissionName: '',
							secondaryMenu: null
						}]
					}
					
					if(permissionArr.length>0){
						permissionArr.forEach(item=>{
							if(item.permissionId=='System'){
								item.secondaryMenu=item.secondaryMenu.filter((el)=>{return el.id!=="10097"})
							}
						})
					}
					// console.log('permissionArr', permissionArr)
					const response = {
						result: {
							role: {
								permissions: permissionArr
							}
						}
					}
					const result = response.result
					if (result.role && result.role.permissions.length > 0) {
						const role = result.role
						role.permissionList = role.permissions.map(per => {
							return per.permissionId
						})
						commit('SET_ROLES', result.role)
					}
					console.log(result,"result")
					resolve(response)
				})
			})
		},
		Logout({
			commit
		}) {
			console.log('Logout')
			commit('SET_TOKEN', '')
			commit('SET_NAME', '')
			commit("SET_AVATAR", "")
			commit('SET_ROLES', [])
			commit('SET_TEACHERID', "")
			storage.remove('ACCESS_TOKEN')
			storage.remove('ACCESS_NAME')
			storage.remove('ACCESS_BADGE')
			
			storage.remove('studentIndex')
			storage.remove('allViewsDate')
			storage.remove('monthViewsDate')
			storage.remove('moduleList')
			storage.remove('monthViewsDate')
			storage.remove('studentId')
			storage.remove('currentTab')
		}
	}
}
export default user
