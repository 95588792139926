import axios from '@/utils/request'

const Api = {
	authLogin: "/api/backyard/auth/login",
	userInfo: "/api/backyard/iam/user/getCurrentUserInfo",
	// permissionInfo: "/api/backyard/iam/resourcePermission/list",
	permissionInfo: "/api/backyard/loginUserInfo",
	informList: "/api/backyard/notification/list",
	badgeNum: "/api/backyard/notification/unreadNum",
	badgeClear: "/api/backyard/notification/read/",
}
// 登录
export function authLogin (params) {
  return axios({
    method: 'post',
    url: Api.authLogin,
    data: params
  })
}
// 获取用户信息
export function getUserInfo (params) {
  return axios({
    method: 'get',
    url: Api.userInfo,
    data: params
  })
}
// 获取权限信息
export function getPermissionInfo (params) {
  return axios({
    method: 'get',
    url: Api.permissionInfo,
    data: params
  })
}

// 获取通知列表
export function getInformList (params) {
  return axios({
    method: 'get',
    url: Api.informList,
    params: params
  })
}

// 获取通知未读数
export function getBadgeNum (params) {
  return axios({
    method: 'get',
    url: Api.badgeNum,
    params: params
  })
}
// 清除通知未读数
export function clearBadgeNum (id, params) {
  return axios({
    method: 'post',
    url: Api.badgeClear + id,
    params: params
  })
}
