<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-27 09:38:59
 * @LastEditTime: 2022-06-11 10:25:29
-->
<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closePreview" :width="'650px'" :visible.sync="preVisible" :close-on-click-modal="true">
		<div class="preview">
			<!-- 图片 -->
			<div class="img_inner">
				<img :src="url" alt="" v-if="type==='img'" />
				<video :src="url" controls class="video_inner" v-else></video>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: 'img'
			},
			url: {
				type: String,
				default: "",
			},
			isOpen: {
				type: Boolean,
				default: function() {
					return false
				}
			},
		},
		watch: {
			isOpen: {
				handler(val) {
					console.log("isOpen", val)
					this.preVisible = val
				},
				deep: true
			}
		},
		data() {
			return {
				preVisible: false
			};
		},
		methods: {
			closePreview() {
				this.$emit('closePreview')
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-modal {
		opacity: 0 !important; // 防止黑屏
	}
	.preview {
		.img_inner {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 100%;
				width: auto;
				height: auto;
			}
		}

		.video_inner {
			width: 800px;
			height: 400px;
		}
	}
</style>
