/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-09 15:51:39
 * @LastEditTime: 2022-05-24 12:36:54
 */
const RouteView = {
	name: 'RouteView',
	render: (h) => h('router-view')
}
const basicRouters = [{
		path: '/',
		component: RouteView,
		redirect: '/login',
		children: [{
				path: '/login',
				name: 'login',
				component: () => import('@/views/user/login'),
				meta: {
					title: '登录'
				}
			},
			{
				path: '/PrivacyAgreement',
				name: 'PrivacyAgreement',
				component: () => import('@/views/user/PrivacyAgreement'),
				meta: {
					title: '隐私政策'
				}
			},
			{
				path: '/useAgreement',
				name: 'useAgreement',
				component: () => import('@/views/user/useAgreement'),
				meta: {
					title: '用户协议'
				}
			},
		]
	},
	{
		path: '/404',
		component: () => import( /* webpackChunkName: "fail" */ '@/views/abnormal/404')
	}
]
export default basicRouters
