/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-10 14:20:02
 */
import Vue from 'vue'
import Vuex from 'vuex'
import breadcrumb from './modules/breadcrumb'
import permission from './modules/permission'
import user from './modules/user'
import routeData from './modules/routeData.js'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		breadcrumb,
		permission,
		user,
		routeData
	},
	getters
})
